@import "../../../styles/variables";

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  flex-wrap: wrap;
  gap: 1.5rem;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  &__group {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      padding: 0 1rem 0 0;
    }
  }
  a {
    color: black;
    text-decoration: none;
  }
  .phone-link {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}
