@import 'variables';

// Fade Animations
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

// Slide Animations
@keyframes slideInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

// Scale Animations
@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulseScale {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

// Rotate Animations
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes wiggle {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  75% { transform: rotate(-5deg); }
}

// Special Effects
@keyframes shimmer {
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

// Page Transition Animations
@keyframes pageEnter {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pageExit {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

// Loading Animations
@keyframes loader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.3); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

// Utility Animation Classes
.animate {
  &-fade-in {
    animation: fadeIn $transition-normal $ease-default forwards;
  }
  
  &-slide-up {
    animation: slideInUp $transition-normal $ease-default forwards;
  }
  
  &-scale-in {
    animation: scaleIn $transition-normal $ease-bounce forwards;
  }
  
  &-float {
    animation: float 3s $ease-smooth infinite;
  }
  
  &-spin {
    animation: spin 1s linear infinite;
  }
  
  // Add delay modifiers
  @for $i from 1 through 10 {
    &-delay-#{$i} {
      animation-delay: #{$i * 100}ms;
    }
  }
}

// Hover Effect Mixins
@mixin hover-lift {
  transition: transform $transition-normal $ease-smooth,
              box-shadow $transition-normal $ease-default;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 20px rgba($black, 0.1);
  }
}

@mixin hover-grow {
  transition: transform $transition-normal $ease-bounce;
  
  &:hover {
    transform: scale(1.05);
  }
}

@mixin hover-shine {
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba($white, 0) 0%,
      rgba($white, 0.3) 100%
    );
    transform: skewX(-25deg);
    transition: all $transition-slow $ease-smooth;
  }
  
  &:hover::before {
    left: 125%;
  }
}

@mixin hover-glow {
  transition: all $transition-normal $ease-default;
  
  &:hover {
    box-shadow: 0 0 15px rgba($primary-color, 0.5);
  }
}
