.products-list {
  width: 100%;
  max-width: 1300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  gap: 2rem;
  place-items: center;
  place-content: space-between;
  padding: 2rem 0;
  @media screen and (max-width: 735px) {
    place-content: center;
  }
  &__product {
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 430px;
    background-color: #FFFFFF;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      width: 270px;
      height: 380px;
    }
  }
}
