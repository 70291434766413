.navbar {
  --navbar-height: 80px;
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  & + * {
    padding-top: var(--navbar-height);
  }

  &__title {
    display: flex;
    align-items: center;

    a {
      font-family: "Pinyon Script", cursive;
      font-size: 2.5rem;
      line-height: 2.5rem;
      color: #2f2e2e;
      text-decoration: none;
      display: flex;
      align-items: center;
      text-align: center;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }

  &__hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }

    div {
      width: 30px;
      height: 3px;
      background: #2f2e2e;
      border-radius: 10px;
      transition: all 0.3s ease;
      position: relative;
      transform-origin: 1px;
    }

    &.open {
      div {
        &:first-child {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-20px);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__links {
    display: flex;
    gap: 2rem;
    align-items: center;

    &__item {
      a {
        font-family: "Montserrat", sans-serif;
        text-decoration: none;
        color: #000;
        font-size: 1rem;
        letter-spacing: 1px;
        padding: 0.5rem;
        transition: all 0.3s ease;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 0;
          height: 2px;
          background-color: #a5b5c8;
          transition: width 0.3s ease;
        }

        &:hover {
          color: #a5b5c8;
          &:after {
            width: 100%;
          }
        }

        &.active {
          color: #a5b5c8;
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__hamburger {
      display: flex;
    }

    &__links {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      flex-direction: column;
      background-color: white;
      padding: 1rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;

      &.open {
        display: flex;
      }

      &__item {
        width: 100%;
        text-align: center;

        a {
          display: block;
          padding: 1rem;
          width: 100%;

          &:hover {
            background-color: #f5f5f5;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }
}
