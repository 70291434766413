@import "../../styles/variables";
@import "../../styles/mixins";

.contact {
  padding: 0 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;

  @media screen and (max-width: 596px) {
    padding: 0 1rem 2rem;
    max-width: 100%;
  }

  &__title {
    font-size: 2.5rem;
    padding: 0 0 3rem 0;
    font-family: "Montserrat", sans-serif;
    color: #2f2e2e;
    position: relative;
    text-align: center;

    @media screen and (max-width: 596px) {
      font-size: 1.75rem;
      padding: 0 0 2rem 0;
    }
  }

  &__content {
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 4rem 0;
    text-align: center;

    @media screen and (max-width: 596px) {
      padding: 0 0 2rem 0;
    }

    &__details {
      font-size: 2rem;
      font-family: "Roboto", sans-serif;
      color: #626262;
      padding: 0 1rem;

      @media screen and (max-width: 596px) {
        font-size: 1.25rem;
      }

      &:not(:last-child) {
        padding-bottom: 2rem;

        @media screen and (max-width: 596px) {
          padding-bottom: 1rem;
        }
      }
    }
  }

  &__more-info {
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__container {
      background-color: #ffffff;
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
      padding: 2rem 3rem;
      width: calc(92% - 6rem);
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 1.5rem;
      flex-wrap: wrap;

      @media screen and (max-width: 596px) {
        padding: 1.5rem;
        width: calc(100% - 3rem);
        gap: 1rem;
      }

      &__contact {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        min-width: 250px;

        @media screen and (max-width: 596px) {
          min-width: 100%;
        }
      }

      &__location {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-width: 250px;

        @media screen and (max-width: 596px) {
          min-width: 100%;
        }
      }

      &__tailor {
        width: 214px;
        align-self: center;

        @media screen and (max-width: 596px) {
          display: none;
        }
      }

      &__title {
        font-size: 2rem;
        padding: 0 0 1rem 0;
        position: relative;
        color: #2f2e2e;

        @media screen and (max-width: 596px) {
          font-size: 1.5rem;
        }

        &::after {
          content: "";
          position: absolute;
          height: 7px;
          background-color: #a5b5c8;
          border-radius: 12px;
          width: calc(100% + 10px);
          top: calc(68%);
          left: 0;
          z-index: 1;
        }
      }

      &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        padding: 1rem 0 0;
      }

      &__group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-width: 250px;
        font-family: "Roboto", sans-serif;
        color: #2f2e2e;

        @media screen and (max-width: 596px) {
          min-width: 100%;
          font-size: 0.9rem;
        }

        & > .long-text {
          word-break: break-word;
        }

        & > *:not(:last-child) {
          padding: 0 1rem 0 0;
        }

        & > img {
          width: 48px;
          height: 48px;

          @media screen and (max-width: 596px) {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}

.about-us {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  section {
    margin-bottom: 4rem;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;

    @media (max-width: 768px) {
      margin-bottom: 3rem;
    }
  }

  h1,
  h2,
  h3 {
    font-family: $font-secondary;
    color: $secondary-color;
  }

  p {
    font-family: $font-primary;
    color: $secondary-light;
    line-height: 1.6;
  }

  .hero {
    text-align: center;
    padding: 4rem 0;
    background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    );
    border-radius: 20px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
      padding: 3rem 1rem;
    }

    h1 {
      font-size: 3.5rem;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    p {
      font-size: 1.5rem;
      color: $secondary-light;

      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
  }

  .story {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: center;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    &__content {
      h2 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }

      p {
        margin-bottom: 1rem;
        font-size: 1.1rem;
      }
    }

    &__image {
      img {
        width: 100%;
        height: auto;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }

  .contact-info {
    text-align: center;

    h2 {
      font-size: 2.5rem;
      margin-bottom: 2rem;

      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 2rem;
    }

    .contact-card {
      background: white;
      padding: 2rem;
      border-radius: 15px;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s ease;
      text-decoration: none;
      color: inherit;
      display: block;

      &:hover {
        transform: translateY(-5px);
      }

      .icon {
        width: 48px;
        height: 48px;
        margin-bottom: 1rem;
      }

      h3 {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        color: black;
      }

      p {
        color: $secondary-light;
        font-size: 1rem;
      }
    }
  }

  .values {
    text-align: center;

    h2 {
      font-size: 2.5rem;
      margin-bottom: 2rem;

      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 2rem;
    }

    .value-card {
      background: white;
      padding: 2rem;
      border-radius: 15px;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      h3 {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        // color: $primary-color;
      }

      p {
        color: $secondary-light;
        font-size: 1rem;
      }
    }
  }
}
