.services {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 4rem);
  padding: 3rem 2rem;
  max-width: 1920px;
  &__title {
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
    color: #2F2E2E;
    font-family: 'Roboto', sans-serif;
    @media screen and (max-width: 790px) {
      font-size: 2rem;
    }
  }
  &__content {
    width: 100%;
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    @media screen and (max-width: 730px) {
      flex-wrap: wrap;
    }
    &__details {
      padding: 3rem 0 2rem 2rem;
      max-width: 600px;
      & > *:not(:last-child) {
        padding: 0 0 2rem 0;
      }
      @media screen and (max-width: 730px) {
        padding: 2rem 0 2rem 1rem;
      }
      &__group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        &__title {
          font-size: 2rem;
          padding: 0 0 1rem 0;
          position: relative;
          color: #2F2E2E;
          font-family: 'Roboto', sans-serif;
          @media screen and (max-width: 790px) {
            font-size: 1.5rem;
          }
          &::after {
            content: "";
            position: absolute;
            height: 7px;
            background-color: #A5B5C8;
            border-radius: 12px;
            width: calc(100% + 10px);
            top: calc(68%);
            left: 0;
            z-index: 1;
          }
        }
        &__subtitle {
          font-size: 1.5rem;
          color: #626262;
          font-family: 'Montserrat', sans-serif;
          @media screen and (max-width: 790px) {
            font-size: 1rem;
          }
        }
      }
      &__image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        @media screen and (max-width: 730px) {
          width: 100%;
          align-self: center;
        }
        & > img {
          width: 362px;
          @media screen and (max-width: 420px) {
            width: 270px;
          }
        }
      }
    }
  }
}
