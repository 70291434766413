@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/animations";

.products {
  @include flex(column, flex-start, center);
  width: 100%;
  gap: $spacing-xl;

  @include mobile {
    gap: $spacing-lg;
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: $spacing-xl;
    justify-items: center;

    @include tablet {
      gap: $spacing-lg;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    @include mobile {
      gap: $spacing-md;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }

  .pagination {
    width: 100%;
    justify-content: center;
    margin: $spacing-lg 0;

    @include mobile {
      margin: $spacing-md 0;
    }
  }
}
