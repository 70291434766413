// Modern Color Palette
$primary-color: #9b6b9e;         // Soft purple
$primary-light: #b794ba;         // Light purple
$primary-dark: #7a4d7d;          // Deep purple

$secondary-color: #2d3436;       // Dark slate
$secondary-light: #636e72;       // Medium slate
$secondary-dark: #1e272e;        // Deep slate

$accent-color-1: #ff9ff3;        // Soft pink
$accent-color-2: #ffeaa7;        // Soft yellow
$accent-color-3: #55efc4;        // Mint green

$background-light: #f5f6fa;      // Light gray
$background-dark: #dcdde1;       // Medium gray

$success-color: #00b894;         // Fresh green
$error-color: #ff7675;          // Soft red
$warning-color: #fdcb6e;        // Warm yellow
$info-color: #74b9ff;          // Sky blue

$white: #ffffff;
$black: #000000;

// Gray Scale
$gray-100: #f7f7f7;
$gray-200: #e3e3e3;
$gray-300: #d1d1d1;
$gray-400: #b9b9b9;
$gray-500: #a0a0a0;
$gray-600: #7e7e7e;
$gray-700: #666666;
$gray-800: #454545;
$gray-900: #262626;

// Gradient Combinations
$gradient-primary: linear-gradient(135deg, $primary-color 0%, $accent-color-1 100%);
$gradient-secondary: linear-gradient(135deg, $secondary-color 0%, $secondary-light 100%);
$gradient-accent: linear-gradient(135deg, $accent-color-1 0%, $accent-color-2 100%);

// Typography
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Montserrat', sans-serif;
$font-decorative: 'Pinyon Script', cursive;

$font-size-xs: 0.75rem;    // 12px
$font-size-sm: 0.875rem;   // 14px
$font-size-base: 1rem;     // 16px
$font-size-md: 1.125rem;   // 18px
$font-size-lg: 1.25rem;    // 20px
$font-size-xl: 1.5rem;     // 24px
$font-size-2xl: 1.875rem;  // 30px
$font-size-3xl: 2.25rem;   // 36px
$font-size-4xl: 3rem;      // 48px

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Spacing
$spacing-unit: 0.25rem;
$spacing-xs: $spacing-unit;        // 4px
$spacing-sm: $spacing-unit * 2;    // 8px
$spacing-md: $spacing-unit * 4;    // 16px
$spacing-lg: $spacing-unit * 6;    // 24px
$spacing-xl: $spacing-unit * 8;    // 32px
$spacing-2xl: $spacing-unit * 12;  // 48px
$spacing-3xl: $spacing-unit * 16;  // 64px

// Breakpoints
$mobile-sm: 320px;
$mobile: 576px;
$tablet: 768px;
$desktop: 1024px;
$desktop-lg: 1200px;
$desktop-xl: 1400px;

// Border radius
$border-radius-xs: 3px;
$border-radius-sm: 5px;
$border-radius-md: 8px;
$border-radius-lg: 12px;
$border-radius-xl: 16px;
$border-radius-full: 9999px;

// Shadows
$shadow-sm: 0 1px 2px rgba($black, 0.05);
$shadow-md: 0 4px 6px rgba($black, 0.1);
$shadow-lg: 0 10px 15px rgba($black, 0.1);
$shadow-xl: 0 20px 25px rgba($black, 0.1);

// Animation Timings
$transition-fast: 200ms;
$transition-normal: 300ms;
$transition-slow: 500ms;
$transition-very-slow: 800ms;

// Animation Curves
$ease-default: cubic-bezier(0.4, 0, 0.2, 1);
$ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
$ease-bounce: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$ease-smooth: cubic-bezier(0.4, 0, 0, 1);

// Animation Presets
$transition-transform: transform $transition-normal $ease-smooth;
$transition-opacity: opacity $transition-normal $ease-default;
$transition-colors: background-color $transition-normal $ease-default,
                   color $transition-normal $ease-default,
                   border-color $transition-normal $ease-default;
$transition-shadow: box-shadow $transition-normal $ease-default;
$transition-all: all $transition-normal $ease-default;

// Z-index
$z-negative: -1;
$z-elevate: 1;
$z-sticky: 100;
$z-drawer: 200;
$z-modal: 300;
$z-popover: 400;
$z-toast: 500;

// Container
$container-padding: $spacing-md;
$container-max-width: 1200px;

// Card
$card-padding: $spacing-md;
$card-border-radius: $border-radius-md;
$card-shadow: $shadow-md;
$card-bg: $white;

// Button
$button-padding-y: $spacing-sm;
$button-padding-x: $spacing-md;
$button-border-radius: $border-radius-sm;
$button-font-weight: $font-weight-medium;

// Input
$input-height: 2.5rem;
$input-padding-y: $spacing-sm;
$input-padding-x: $spacing-md;
$input-border-radius: $border-radius-sm;
$input-border-color: $gray-300;
$input-focus-border-color: $primary-color;

// Animation
$animation-duration-fast: 200ms;
$animation-duration-normal: 300ms;
$animation-duration-slow: 500ms;
$animation-timing: cubic-bezier(0.4, 0, 0.2, 1);
