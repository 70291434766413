.products-header {
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 3rem 0;
  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
  &__details {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    flex: 1;
    max-width: 850px;
    padding: 0 2rem 2rem 0;
    @media screen and (max-width: 760px) {
      text-align: center;
      padding: 0 0 2rem 0;
    }
    @media screen and (max-width: 1020px) {
      font-size: 1.5rem;
    }
  }
  & > img {
    border-radius: 50%;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
    width: 280px;
    height: 280px;
  }
}
