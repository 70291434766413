.home-banner {
  width: calc(100% - 6rem);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 2rem 3rem;
  background-image: url("homeTop.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  color: azure;
  isolation: isolate;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
  }
  &__title {
    font-size: 2.5rem;
    text-align: center;
    padding: 0 0 1rem 0;
    font-family: "Roboto", sans-serif;
  }
  &__subtitle {
    font-size: 1.5rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    max-width: 900px;
  }
  @media screen and (max-width: 580px) {
    &__title {
      font-size: 2rem;
    }
    &__subtitle {
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 420px) {
    &__title {
      font-size: 1.5rem;
    }
    &__subtitle {
      font-size: 1rem;
    }
  }
}
