.products-filters {
  width: calc(100% - 4rem);
  padding: 0 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &__title {
    font-size: 2.5rem;
    padding: 0 0 3rem 0;
    font-family: 'Montserrat', sans-serif;
    color: #2F2E2E;
    position: relative;
    text-align: center;
    @media screen and (max-width: 750px) {
      font-size: 2rem;
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    align-items: center;
    padding: 12px 20px;
    border-radius: 10px;
    flex-wrap: wrap;
    @media screen and (max-width: 680px) {
      flex-direction: column;
    }
    & > *:not(:last-child) {
      padding: 0 2rem 0 0;
      @media screen and (max-width: 680px) {
        padding: 0 0 2rem 0;
      }
    }
    &__filter {
      padding: 8px 22px;
      color: #2F2E2E;
      border-radius: 12px;
      font-size: 1.5rem;
      cursor: pointer;
      text-align: center;
      min-width: 100px;
      &__active {
        background-color: #A5B5C8;
        color: #FFFFFF;
      }
      &:hover {
        background-color: #A5B5C8;
        color: #FFFFFF;
      }
    }
  }
}
