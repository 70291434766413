@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/animations";

// Light blue color variables
$pagination-blue: #a5b5c8;
$pagination-dark-blue: #1e88e5;

.pagination {
  @include flex(row, center, center);
  gap: $spacing-sm;
  margin: $spacing-xl 0;

  &__button {
    @include button-base;
    min-width: 40px;
    height: 40px;
    padding: 0;
    border-radius: $border-radius-full;
    background: $white;
    color: $secondary-color;
    border: 2px solid $pagination-blue;
    transition: all $transition-normal $ease-bounce;
    font-size: 1rem;

    // Arrow buttons
    &:first-child,
    &:last-child {
      background: $pagination-blue;
      border-color: $pagination-blue;
      color: $white;

      &:hover:not(:disabled) {
        background: $pagination-dark-blue;
        border-color: $pagination-dark-blue;
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba($pagination-blue, 0.3);
      }

      &:disabled {
        background: $gray-300;
        border-color: $gray-300;
        opacity: 0.7;
      }
    }

    // Number buttons
    &:not(:first-child):not(:last-child) {
      background: $white;
      border-color: $pagination-blue;
      color: $pagination-blue;

      &:hover:not(:disabled) {
        border-color: $pagination-dark-blue;
        background: $pagination-dark-blue;
        color: $white;
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba($pagination-blue, 0.2);
      }

      &.active {
        background: $pagination-blue;
        color: $white;
        transform: scale(1.1);
        box-shadow: 0 4px 12px rgba($pagination-blue, 0.3);

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      border-color: $gray-300;
      background: $gray-100;
    }

    i {
      font-size: $font-size-sm;
    }
  }

  @include mobile {
    gap: $spacing-xs;
    margin: $spacing-lg 0;
    width: 100%;
    justify-content: center;

    &__button {
      min-width: 32px;
      height: 32px;
      font-size: 0.875rem;

      i {
        font-size: 0.75rem;
      }

      &:not(:first-child):not(:last-child) {
        @media (max-width: 360px) {
          display: none;
          
          &.active {
            display: flex;
          }
        }
      }
    }
  }
}
