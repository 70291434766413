.events {
  &__hero {
    min-height: 40vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4rem 2rem;

    &-content {
      max-width: 800px;
      color: white;

      h1 {
        font-size: 3rem;
        margin-bottom: 1.5rem;
        font-family: "Pinyon Script", cursive;

        @media (max-width: 768px) {
          font-size: 2.5rem;
        }
      }

      p {
        font-size: 1.2rem;
        line-height: 1.6;
        font-family: "Montserrat", sans-serif;

        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }

  &__gallery {
    padding: 4rem 2rem;

    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 2rem;
      max-width: 1400px;
      margin: 0 auto;
    }

    &-item {
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      aspect-ratio: 2/3;

      &:hover {
        transform: translateY(-5px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: transform 0.3s ease;
      }

      &:hover img {
        transform: scale(1.05);
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 3rem;
    padding: 1rem;

    &-button {
      background: white;
      border: 1px solid #a5b5c8;
      color: #a5b5c8;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;
      font-family: "Montserrat", sans-serif;
      min-width: 40px;

      &:hover:not(.disabled) {
        background: #a5b5c8;
        color: white;
      }

      &.active {
        background: #a5b5c8;
        color: white;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
