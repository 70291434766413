// Layout mixins
@mixin flex($direction: row, $justify: flex-start, $align: stretch, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin grid($columns: 1, $gap: $spacing-md) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  gap: $gap;
}

// Container
@mixin container {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 $container-padding;
}

// Media queries
@mixin mobile-sm {
  @media (max-width: $mobile-sm) { @content; }
}

@mixin mobile {
  @media (max-width: $mobile) { @content; }
}

@mixin tablet {
  @media (max-width: $tablet) { @content; }
}

@mixin desktop {
  @media (max-width: $desktop) { @content; }
}

@mixin desktop-lg {
  @media (max-width: $desktop-lg) { @content; }
}

// Typography
@mixin text-style($size: base, $weight: regular, $color: $secondary-color) {
  font-size: var(--font-size-#{$size});
  font-weight: var(--font-weight-#{$weight});
  color: $color;
}

@mixin truncate($lines: 1) {
  @if $lines == 1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

// Interactive elements
@mixin button-base {
  @include flex(row, center, center);
  padding: $button-padding-y $button-padding-x;
  border-radius: $button-border-radius;
  font-weight: $button-font-weight;
  transition: all $transition-normal;
  cursor: pointer;
  border: none;
  outline: none;
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

@mixin hover-lift {
  transition: transform $transition-normal, box-shadow $transition-normal;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: $shadow-md;
  }
}

@mixin focus-ring {
  &:focus-visible {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }
}

// Card styles
@mixin card-base {
  background: $card-bg;
  border-radius: $card-border-radius;
  padding: $card-padding;
  box-shadow: $card-shadow;
}

// Animations
@mixin fade-in($duration: $animation-duration-normal) {
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  animation: fadeIn $duration $animation-timing;
}

@mixin slide-in($direction: 'up', $distance: 20px, $duration: $animation-duration-normal) {
  @keyframes slideIn#{capitalize($direction)} {
    from {
      opacity: 0;
      @if $direction == 'up' {
        transform: translateY($distance);
      } @else if $direction == 'down' {
        transform: translateY(-$distance);
      } @else if $direction == 'left' {
        transform: translateX($distance);
      } @else if $direction == 'right' {
        transform: translateX(-$distance);
      }
    }
    to {
      opacity: 1;
      transform: translate(0);
    }
  }
  animation: slideIn#{capitalize($direction)} $duration $animation-timing;
}

// Utilities
@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: $gray-200;
    border-radius: $border-radius-full;
  }
  
  &::-webkit-scrollbar-thumb {
    background: $gray-400;
    border-radius: $border-radius-full;
    
    &:hover {
      background: $gray-500;
    }
  }
}

@mixin glass-effect {
  background: rgba($white, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

@mixin aspect-ratio($width: 16, $height: 9) {
  position: relative;
  
  &::before {
    content: '';
    display: block;
    padding-top: ($height / $width) * 100%;
  }
  
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
