@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import "./styles/variables";
@import "./styles/mixins";
@import "./styles/animations";

//font-family: 'Pinyon Script', cursive;
//font-family: 'Roboto', sans-serif;
//font-family: 'Montserrat', sans-serif;
//  max-width: 1920px;
html {
  background-color: #f4eedc;
}
.app {
  min-height: calc(100dvh - var(--navbar-height, 7rem));
  display: flex;
  flex-direction: column;
  background-color: $background-light;
  padding-top: var(--navbar-height, 7rem);

  &__content {
    flex: 1;
    width: calc(100% - (2 * $spacing-lg));
    max-width: $container-max-width;
    margin: 0 auto;
    padding: $spacing-xl $spacing-lg;

    @include tablet {
      padding: $spacing-lg $spacing-md;
      max-width: calc(100% - #{$spacing-md * 2});
    }

    @include mobile {
      padding: $spacing-md $spacing-sm;
      max-width: calc(100% - #{$spacing-sm * 2});

      > * {
        width: calc(100% - (2 * $spacing-sm));
      }
    }
  }
}
